// Import all the channels to be used by Action Cable
import consumer from "./consumer"

const controller = document.body.dataset.controller
const action = document.body.dataset.action
const first = (controller == 'client_document_scans' && ['show', 'update'].includes(action))
const last = (controller == 'client/documents' && action == 'scan')

if (first || last) {
  consumer.subscriptions.create({channel: "ScanChannel", id: document.getElementById('client').dataset.clientId}, {
    connected() {
      console.log("Connected to ScanChannel");
    },

    disconnected() {
      console.log("Disconnected from ScanChannel");
    },

    received(data) {
      console.log("Received data:", data);
      window.location.href = data['location']
    }
  });
}
